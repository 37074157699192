import React from 'react'

export default function Error() {
  return (
    <div className="flex content-center justify-center flex-wrap h-screen">
      <h1 className="text-center text-2xl font-semibold w-full">Oops</h1>
      <div className="text-center w-full">Something went wrong.</div>
      <div className="text-center w-full">
        Ideally, you'd never see this page. Our apologies. The Flyway crew has been notified and will be working to
        resolve this issue.
      </div>
    </div>
  )
}
