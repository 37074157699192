import './index.css'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import ReactDOM from 'react-dom'
import React from 'reactn'

import App from './App'
import config from './config'
import * as serviceWorker from './serviceWorker'
import initializeState from './state'

if (config.env.environment !== 'development') {
  Sentry.init({
    dsn: 'https://e14c0eba361a49cca599553753b1f550@o441212.ingest.sentry.io/5426023',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}

initializeState()

const root = document.getElementById('root') as HTMLElement
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  root
)

if (config.env.environment !== 'development') {
  serviceWorker.register()
}
