import { AirportSearchResult } from '../generated-types'
import { graphQlRequest } from '../utils/fetchGraphQL'

const graphQuery = `
  query SearchBarQuery($query: String!, $start: Int, $limit: Int) {
    airportSearch(query: $query, start: $start, limit: $limit) {
      cursor {
        count
        limit
        start
        totalPages
        pagesRemaining
        currentPage
      }
      items {
        icaoIdentifier
        identifier
        name
        servingCity
        name
      }
    }
  }
`

export default async function loadSearchResults(query: string, minLength = 2) {
  if (query.trim().length > minLength) {
    const { data } = (await graphQlRequest(graphQuery, { query: `${query}*`, start: 0, limit: 30 })) as {
      data: { airportSearch: { items: AirportSearchResult[] } }
    }
    return data.airportSearch.items
  } else {
    return []
  }
}
