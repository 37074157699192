import { useState } from 'react'

import Logger from '../utils/dev-logging'

export function getFromLocalStorage<T>(key: string, initialValue: T): T {
  try {
    // Get from local storage by key
    const item = window.localStorage.getItem(key)
    // Parse stored json or if none return initialValue
    return (item ? JSON.parse(item) : initialValue) as T
  } catch (error) {
    // If error also return initialValue
    Logger.error(error)
    return initialValue as T
  }
}

export function setToLocalStorage<T>(key: string, value: T, storedValue?: T, setStoredValue = (_: T) => {}) {
  try {
    // Allow value to be a function so we have same API as useState
    const valueToStore = value instanceof Function ? value(storedValue) : value
    // Save state
    setStoredValue(valueToStore)
    // Save to local storage
    window.localStorage.setItem(key, JSON.stringify(valueToStore))
  } catch (error) {
    // A more advanced implementation would handle the error case
    Logger.error(error)
  }
}

export default function useLocalStorage<T>(key: string, initialValue: T): [T, (value: T) => void] {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    return getFromLocalStorage(key, initialValue)
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T) => {
    setToLocalStorage(key, value as any, storedValue, setStoredValue)
  }

  return [storedValue, setValue]
}
