import { Variables } from 'relay-runtime'

import config from '../config'
import { API_AUTH_TOKEN } from '../config/constants'

async function fetchGraphQL(text: string | null | undefined, variables: Variables) {
  return graphQlRequest<any>(text, variables)
}

type RequestCache = {
  [k: string]: any
}

const cache = {} as RequestCache
const inFlight = {} as RequestCache

type FetchOptions = {
  fetchPolicy: 'store-only' | 'network-only' | 'store-or-network'
  recursion?: boolean
}

function cacheKey(text: string | null | undefined, variables: Variables) {
  return `${text}${JSON.stringify(variables)}`
}

function fromCache(text: string | null | undefined, variables: Variables) {
  return cache[cacheKey(text, variables)]
}

function putToCache(text: string | null | undefined, variables: Variables, value: any) {
  cache[cacheKey(text, variables)] = value
}

function isInFlight(text: string | null | undefined, variables: Variables) {
  return !!inFlight[cacheKey(text, variables)]
}

function setIsInFlight(text: string | null | undefined, variables: Variables) {
  inFlight[cacheKey(text, variables)] = true
}

function clearIsInFlight(text: string | null | undefined, variables: Variables) {
  inFlight[cacheKey(text, variables)] = null
}

async function graphQlRequest<TReturn>(text: string | null | undefined, variables: Variables, options?: FetchOptions) {
  try {
    if (isInFlight(text, variables)) {
      return new Promise(async (resolve) => {
        setTimeout(
          () =>
            graphQlRequest(text, variables, {
              ...options,
              fetchPolicy: options?.fetchPolicy || 'network-only',
              recursion: true,
            }).then((res) => resolve(res)),
          100
        )
      })
    }
    setIsInFlight(text, variables)
    const opts = options || { fetchPolicy: 'network-only' }
    if (opts.fetchPolicy === 'store-only') {
      return fromCache(text, variables)
    }

    if (opts.fetchPolicy === 'store-or-network') {
      const cached = fromCache(text, variables)
      if (cached) return cached
    }

    const headers = {
      Authorization: `Bearer ${API_AUTH_TOKEN}`,
      'Content-Type': 'application/json',
    }

    const response = await fetch(config.urls.graphql, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        query: text,
        variables,
      }),
    })

    const result = (await response.json()) as TReturn
    if (opts.fetchPolicy === 'store-or-network') {
      putToCache(text, variables, result)
    }
    // if (opts.fetchPolicy === 'store-only') {
    //   putToCache(text, variables, result)
    // }
    return result
  } catch {
  } finally {
    clearIsInFlight(text, variables)
  }
}
export { graphQlRequest }
export default fetchGraphQL
