import ChartWithAirport from '../Types/ChartWithAirport'
import Logger from './dev-logging'
import { graphQlRequest } from './fetchGraphQL'

const updateSessionMutation = `
  mutation UpdateSessionMutation($id: String!, $charts: [InputChart!]!) {
    chartSessionUpdate(id: $id, charts: $charts) {
      id
    }
  }
`

const createSessionMutation = `
  mutation CreateChartSession {
    chartSessionCreate {
      id
    }
  }
`
export async function createChartStateOnServer() {
  const {
    data: {
      chartSessionCreate: { id },
    },
  } = await graphQlRequest(createSessionMutation, {}, { fetchPolicy: 'network-only' })
  Logger.log(id)
  return id
}

function filterRelay(item: { [k: string]: any }) {
  return Object.keys(item)
    .filter((k) => k !== '__fragmentOwner' && k !== '__fragments' && k !== '__id')
    .reduce((obj, curr) => {
      const clone = { ...obj }
      clone[curr] = item[curr]
      return clone
    }, {} as { [k: string]: any })
}

export async function updateChartStateOnServer(id: string, selected: ChartWithAirport[]) {
  const payload = {
    id,
    charts: selected.map((item) => {
      const c = { ...filterRelay(item) }
      c.airport = { identifier: c.airport.identifier }
      return c
    }),
  }
  const res = await graphQlRequest(updateSessionMutation, payload, { fetchPolicy: 'network-only' })
  Logger.log(res)
  if (res.errors) {
    throw new Error('ERROR UPDATING')
  } else {
    return res.data.chartSessionUpdate.id
  }
}

const getChartStateQuery = `
query GetChartStateQuery($id: String!) {
  chartSession(id: $id) {
    charts {
      chartCode
      airport { identifier }
      chartName
      class
      downloaded
      faanfd18
      href
      pageCount
      pdfName
      valid
    }
  }
}`

export async function getChartStateFromServer(id: string) {
  const response = await graphQlRequest(getChartStateQuery, { id })
  if (response) {
    return response.data.chartSession.charts
  }
  return []
}
