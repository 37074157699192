import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useEffect } from 'reactn'

export default function Footer() {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setReady(true)
    }, 1500)
  })

  return !ready ? (
    <></>
  ) : (
    <footer className="w-full bg-white text-themeblack">
      <div className="flex items-center">
        <div className="m-4">
          <a href="https://m.me/Useflyway" target="_blank" rel="noopener noreferrer">
            <h1>Contact Us</h1>
          </a>
        </div>
        <div className="m-4">
          <Link to="/terms">Term of Use</Link>
        </div>
      </div>
    </footer>
  )
}
