import config from '../config'

const Logger = {
  info: (...all: any) => {
    if (config.env.logging.enabled) console.info(...all)
  },
  warn: (...all: any) => {
    if (config.env.logging.enabled) console.warn(...all)
  },
  error: (...all: any) => {
    if (config.env.logging.enabled) console.error(...all)
  },
  log: (...all: any) => {
    if (config.env.logging.enabled) console.log(...all)
  },
}

export default Logger
