import React from 'react'
import { Helmet } from 'react-helmet'

export default function AboutPage() {
  return (
    <section className="mt-8 flex justify-center">
      <Helmet>
        <title>Flyway - About | Faster, Easier, Flight Planning</title>
      </Helmet>
      <div className="md:flex w-1/2">
        <div className="md:flex-shrink-0"></div>
        <div className="mt-4 md:mt-0 md:ml-6">
          <div className="uppercase tracking-wide text-lg text-themeblue-500 font-bold">About Flyway</div>
          <p className="mt-2 block">
            We're the best source of current airport information for flight planning, With great features like: 
          </p>
          <ul className="list-disc ml-4 mt-4">
            <li>One click printing of all the procedures you need for your flight, giving you a reliable backup</li>
            <li>quick access to weather informaiton and best wind data</li>
            <li>Interactive aerial maps</li>
            <li>Runway information and lighting</li>
            <li>Approach lighting</li>
            <li>And more. Start exploring.</li>
          </ul>
        </div>
      </div>
    </section>
  )
}
