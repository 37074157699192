import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ActionMeta } from 'react-select'
import AsyncSelect from 'react-select/async'

import loadSearchResults from '../../data/search'
import { AirportSearchResult } from '../../generated-types'
import emptyAsNull from '../../utils/empty-as-null'

function AirportOption({ innerProps, innerRef, ...props }: any): JSX.Element {
  const [city, state] = props.data.servingCity.split(', ')
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="px-2 my-1 cursor-pointer hover:bg-themelightgrey focus:bg-themeblue-400 focus:outline-none rounded">
      <div className="font-medium text-themeblack">{props.data.name}</div>
      <div className="font-light text-themeblack">
        {`${props.data.identifier} - `}
        <span className="capitalize">{`${city.toLowerCase()}, ${state}`}</span>
      </div>
    </div>
  )
}

async function loadResults(query: string, setData: Function) {
  const results = await loadSearchResults(query, 0)
  setData(results)
}

function generateNoOptionsMessage(obj: { inputValue: string }) {
  if (obj.inputValue.trim().length === 0) {
    return 'Search using City and State, ICAO, Identifier, or Name...'
  }
  if (obj.inputValue.trim().length <= 1) {
    return 'Enter more characters...'
  }
  return `No results for '${obj.inputValue}...'`
}

type SearchBarProps = {
  placeholder?: any
  defaultValue?: any
  defaultValues?: Array<AirportSearchResult>
  multi?: boolean
  onSelectionChanged?: (selected: ReadonlyArray<AirportSearchResult>) => void
}

function SearchBar({ multi, onSelectionChanged, defaultValue, defaultValues, placeholder }: SearchBarProps) {
  const [value, setValue] = useState<string | null>(defaultValue)
  const [values, setValues] = useState<Array<AirportSearchResult> | null | undefined>(defaultValues)

  const history = useHistory()

  function handleChange(value: any, action: ActionMeta<any>) {
    if (['select-option', 'remove-value', 'pop-value', 'clear'].includes(action.action)) {
      if (multi) {
        const coalesced = (value as unknown) as Array<AirportSearchResult>
        setValues(coalesced)
        if (onSelectionChanged) {
          onSelectionChanged(coalesced)
        }
      } else {
        const coalesced = (value as unknown) as AirportSearchResult
        setValue(coalesced.identifier || '')
      }
    }
  }

  useEffect(() => {
    if (multi) {
      if (values) {
      }
    } else {
      if (value) {
        history.push(`/airports/${value}`)
      }
    }
  }, [value, history, multi, values])

  const dv = defaultValue || defaultValues

  return (
    <AsyncSelect
      placeholder={placeholder}
      blurInputOnSelect={false}
      isMulti={multi}
      cacheOptions
      value={dv}
      loadOptions={loadResults}
      controlShouldRenderValue
      components={{ Option: AirportOption }}
      getOptionLabel={(option) => `${option.name} - ${emptyAsNull(option.icaoIdentifier) || option.identifier}`}
      getOptionValue={(option) => {
        return option.identifier
      }}
      noOptionsMessage={generateNoOptionsMessage}
      onChange={handleChange}
      autoFocus></AsyncSelect>
  )
}

export default SearchBar
