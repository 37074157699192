import React from 'react'

export default function NotFound() {
  return (
    <div className="flex content-center justify-center flex-wrap h-screen">
      <h1 className="text-center text-2xl font-semibold w-full">404</h1>
      <div className="text-center w-full">Page Not Found</div>
      <div className="text-center w-full">
        It looks like the page you were looking for doesn't exist. Try searching for an airport above.
      </div>
    </div>
  )
}
