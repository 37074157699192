import {
  API_BASE_URL,
  API_HOST,
  API_PATH,
  API_PROTOCOL,
  IS_LOCAL,
  LEAFLET_SATELLITE,
  MAPBOX_DEFAULT_MAP,
  MAPBOX_SATELLITE,
} from './constants'
import local from './local'

interface Hash {
  [k: string]: any
}
export interface Config extends Hash {
  env: {
    environment: 'development' | 'production'
    logging: {
      enabled: boolean
      reducerStack: boolean
    }
  }
  urls: {
    apiBase: string
    graphql: string
    faaPdfBasePath: string
    pdfProxy: string
  }
  mapbox: {
    defaultMap: string
    mapboxSatellite: string
    leafletSatellite: string
  }
  state: {
    saveToLocalStorage: boolean
  }
}

const localConfig = local || {}

const defaultConfig: Config = {
  env: {
    environment: IS_LOCAL ? 'development' : 'production',
    logging: {
      enabled: IS_LOCAL ? true : false,
      reducerStack: false,
    },
  },
  urls: {
    apiBase: API_BASE_URL,
    graphql: `${API_PROTOCOL}${API_HOST}${API_PATH}/graphql`,
    faaPdfBasePath: 'https://aeronav.faa.gov/d-tpp',
    pdfProxy: `${API_BASE_URL}/charts/pdf`,
  },
  mapbox: {
    defaultMap: MAPBOX_DEFAULT_MAP,
    mapboxSatellite: MAPBOX_SATELLITE,
    leafletSatellite: LEAFLET_SATELLITE,
  },
  state: {
    saveToLocalStorage: true,
  },
} as Config

const mergedKeys = Object.keys({ ...defaultConfig, ...localConfig })

const config: Config = defaultConfig

mergedKeys.forEach((k) => (config[k] = { ...(defaultConfig[k] || {}), ...(localConfig[k] || {}) }))

export default config
