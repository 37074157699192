import { State } from 'reactn/default'

import { Airport, AirportSearchResult } from '../generated-types'
import ChartWithAirport from '../Types/ChartWithAirport'
import { getChartStateFromServer } from './chart-state'
import { chartComparer } from './chart-utils'
import { graphQlRequest } from './fetchGraphQL'

const fetchAirportChartsQuery = `query fetchAirportChartsQuery($ids: [ID]!) {
  airports(ids: $ids) {
    airports {
      identifier
      icaoIdentifier
      name
      servingCity
      charts {
        chartCode
        chartName
        class
        faanfd18
        href
        pdfName
        pageCount
      }
    }
  }
}`

const RouteRehydrator = {
  async rehydrate(id: string, state: State) {
    const charts = (await getChartStateFromServer(id)) as ChartWithAirport[]

    const airportIds = charts
      .map((chart: ChartWithAirport) => chart.airport.identifier)
      .reduce((all, s) => {
        if (all.includes(s)) return all
        return all.concat([s])
      }, [] as string[])

    const {
      data: {
        airports: { airports: fetchedAirports },
      },
    } = (await graphQlRequest(fetchAirportChartsQuery, { ids: airportIds }, { fetchPolicy: 'store-or-network' })) as {
      data: { airports: { airports: Airport[] } }
    }

    const newState = charts.reduce((state, chart) => {
      const { selected, airports } = state
      const airport = fetchedAirports.find((a) => a.identifier === chart.airport.identifier) as Airport
      return {
        ...state,
        selected: selected.find((c) => chartComparer(c, chart, true)) ? selected : [...selected, { ...chart, airport }],
        airports: airports.find((a) => a.identifier === chart.airport.identifier)
          ? airports
          : ([...airports, airport] as AirportSearchResult[]),
      }
    }, state)

    return newState
  },
}

export default RouteRehydrator
