import './App.css'

import { ErrorBoundary } from 'react-error-boundary'
import ReactGA from 'react-ga'
import { RelayEnvironmentProvider } from 'react-relay/hooks'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import React, { Suspense, useState } from 'reactn'

import Error from './components/Error'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import NotFound from './components/NotFound'
import TermsOfServicePage from './components/TermsOfService'
import AboutPage from './pages/AboutPage'
import RelayEnvironment from './RelayEnvironment'

const AirportPage = React.lazy(() => import('./pages/AirportPage'))
const LandingPage = React.lazy(() => import('./pages/LandingPage'))
/*
  Naming the chunk here to all for the injection of the airport
  data in the build step.
  @see <root>/scripts/prerender-index.js
*/
const AirportsPage = React.lazy(() => import(/* webpackChunkName: "airports-index" */ './pages/AirportsPage'))
const CartPage = React.lazy(() => import('./pages/CartPage'))

// if (config.env.environment === 'production') {
//   ReactGA.initialize('UA-168239010-1')
// }

export default function App() {
  const [heightOffset, setHeightOffset] = useState('0')
  const updateHeightOffset = (height: string) => setHeightOffset(height)

  ReactGA.pageview(window.location.pathname + window.location.search)

  return (
    <div className="flex-grow">
      <ErrorBoundary fallback={<Error />}>
        <RelayEnvironmentProvider environment={RelayEnvironment}>
          <Router>
            <Navbar updateHeightOffset={updateHeightOffset} />

            <Suspense fallback={''}>
              <Switch>
                <Route exact path="/">
                  <LandingPage heightOffset={heightOffset} />
                </Route>
                <Route path="/chart-selections/:route*">
                  <CartPage heightOffset={heightOffset} />
                </Route>
                <Route path="/cart/:route*">
                  <CartPage heightOffset={heightOffset} />
                </Route>
                <Route exact path="/about">
                  <AboutPage />
                </Route>
                <Route exact path="/airports">
                  <AirportsPage />
                </Route>
                <Route exact path="/airports/state/:state">
                  <AirportsPage />
                </Route>
                <Route exact path="/airports/state/:state/:city">
                  <AirportsPage />
                </Route>
                <Route exact path="/airports/:airportId*">
                  <AirportPage />
                </Route>
                <Route path="/terms">
                  <TermsOfServicePage />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </Suspense>
            <Footer />
          </Router>
        </RelayEnvironmentProvider>
      </ErrorBoundary>
    </div>
  )
}
