import { addCallback, addReducer, setGlobal } from 'reactn'
import { State } from 'reactn/default'

import config from '../config'
import { AirportSearchResult } from '../generated-types'
import { getFromLocalStorage, setToLocalStorage } from '../hooks/use-local-storage'
import ChartWithAirport from '../Types/ChartWithAirport'
import addSessionIdToUrl from '../utils/add-session-id-to-url'
import { updateChartStateOnServer } from '../utils/chart-state'
import { chartComparer } from '../utils/chart-utils'
import Logger from '../utils/dev-logging'
import RouteRehydrator from '../utils/route-rehydrator'

function simpleRouteMatch(url: string) {
  return url.split('/route/')[1]
}

export default function initializeState() {
  const defaultState: State = {
    homeAirport: null,
    lastHomeRedirect: null,
    initializationState: 'none',
    airportChartsLoading: false,
    airports: [],
    charts: [],
    selected: [],
    wasPrintRequest: false,
    printRequestHistory: null,
    chartsSession: {
      status: 'none',
      id: null,
    },
  }

  addReducer('selectChart', (state, dispatch, chart, airport) => {
    const selected = state.selected.find((c) => chartComparer(chart, c, true))
      ? state.selected
      : ([...state.selected.filter((c) => !chartComparer(c, chart, true)), { ...chart, airport }] as ChartWithAirport[])
    const airports = state.airports.find((a) => a.identifier === airport.identifier)
      ? state.airports
      : ([...state.airports, airport] as AirportSearchResult[])
    // @ts-ignore
    dispatch.chartSessionUpdate(selected)
    return {
      selected,
      airports,
    }
  })

  // @ts-ignore
  addReducer('selectCharts', (state, dispatch, charts, airport) => {
    const selected = [
      ...state.selected,
      // Filter any already selected charts passed in from the
      // supplied list, then append the remainder to the list
      ...charts.filter((c) => !state.selected.find((f) => chartComparer(c, f, true))).map((c) => ({ ...c, airport })),
    ]
    const airports = state.airports.find((a) => a.identifier === airport.identifier)
      ? state.airports
      : [...state.airports, airport]

    dispatch.chartSessionUpdate(selected)

    return {
      selected,
      airports,
    }
  })

  addReducer('deselectChart', (state, dispatch, chart) => {
    const selected = [...state.selected.filter((c) => !chartComparer(c, chart, true))]
    const airports = state.airports.filter((a) =>
      (selected as ChartWithAirport[]).find((c) => c.airport.identifier === a.identifier)
    )
    dispatch.chartSessionUpdate(selected)
    return {
      selected,
      airports,
    }
  })

  addReducer('deselectCharts', (state, dispatch, charts) => {
    const selected = [...state.selected.filter((c) => !charts.find((f) => chartComparer(f, c, true)))]
    const airports = state.airports.filter((a) =>
      (selected as ChartWithAirport[]).find((c) => c.airport.identifier === a.identifier)
    )

    dispatch.chartSessionUpdate(selected)
    return { selected, airports }
  })

  addReducer('deselectAllCharts', (state, dispatch) => {
    dispatch.chartSessionUpdate([])
    return {
      selected: [],
      airports: [],
    }
  })

  addReducer('chartSessionUpdate', (state, dispatch, selected) => {
    if (selected.length > 0) {
      updateChartStateOnServer(state.chartsSession.id || '<generate>', selected as ChartWithAirport[]).then((id) => {
        // @ts-ignore
        dispatch.chartSessionUpdated(id)
      })
      return { ...state, chartsSession: { ...state.chartsSession, status: 'updating' } }
    } else {
      addSessionIdToUrl(null)
      return { ...state, chartsSession: { ...state.chartsSession, id: null, status: 'none' } }
    }
  })

  addReducer('chartSessionUpdated', (state, dispatch, id) => {
    if (state.wasPrintRequest) {
      // eslint-disable-next-line
      const history = state.printRequestHistory
      const target = `/chart-selections/route/${id}`
      if (!window.location.href.includes(target)) {
        history.push(target)
      }
      return {
        ...state,
        wasPrintRequest: false,
        printRequestHistory: null,
        chartsSession: { ...state.chartsSession, id, status: 'created' },
      }
    } else {
      addSessionIdToUrl(id)
      return { ...state, chartsSession: { ...state.chartsSession, id, status: 'created' } }
    }
  })

  addReducer('updateSelectedAirports', (state, dispatch, selected) => {
    const newSelected = state.selected.filter((c) =>
      (selected || []).find((a) => a.identifier === c.airport.identifier)
    )
    dispatch.chartSessionUpdate(newSelected)

    return {
      airports: selected || [],
      selected: newSelected,
    }
  })

  // @ts-ignore
  addReducer('printAllCharts', async (state, dispatch, selected, airport, history) => {
    const next = await dispatch.selectCharts(selected, airport)
    // eslint-disable-next-line
    if (next.chartsSession.id) {
      const target = `/chart-selections/route/${next.chartsSession.id}`
      if (!window.location.href.includes(target)) {
        history.push(target)
      }
    }
    return {
      ...next,
      wasPrintRequest: true,
      printRequestHistory: history,
    }
  })

  addCallback((state) => {
    if (config.state.saveToLocalStorage) {
      Logger.info('Saving state...')
      setToLocalStorage('bundler-state', state)
      Logger.info('State saved...')
    }
    if (config.env.environment === 'development') {
      const w = window as any
      w.__flywayState = state
    }
    return null
  })

  const match = simpleRouteMatch(window.location.href)
  Logger.info('Loading saved state...')
  const initialState = { ...defaultState, ...getFromLocalStorage('bundler-state', defaultState) }
  Logger.info('Saved state loaded...')
  if (match) {
    Logger.info(`Url provided session: ${match}`)
    setGlobal({ ...initialState, initializationState: 'pending' })
    RouteRehydrator.rehydrate(match, initialState).then((state) => {
      setGlobal({ ...state, initializationState: 'ready' })
    })
  } else {
    Logger.info('Setting global state...')
    setGlobal({ ...initialState, initializationState: 'ready' })
    Logger.info('Global state set...')
  }
}
