import { Airport, Chart } from '../generated-types'
import { diff } from './array-utils'

export function chartComparer(a: Chart, b: Chart, useName?: true | false): boolean {
  return a.chartCode === b.chartCode && (useName ? b.chartName === a.chartName : true) && a.pdfName === b.pdfName
}

export function airportChartComparer(a: Airport, b: Airport): boolean {
  return (
    a.identifier === b.identifier ||
    diff(a.charts as Chart[], b.charts as Chart[], chartComparer)[1].length === 0
  )
}
