function groupBy<T>(arg: ReadonlyArray<T>, field: string) {
  const res = arg.reduce((all, curr) => {
    const clone = { ...all }
    const curried = (curr as any) as { [k: string]: any }
    const key = curried[field] as string
    if (!clone[key]) {
      clone[key] = []
    }
    clone[key].push(curr)
    return clone
  }, {} as { [k: string]: T[] })
  return res
}

function diff<T>(a: readonly T[], b: readonly T[], comparator: (a: T, b: T) => true | false) {
  const inANotB = a.reduce((diffs, curr) => {
    const found = b.find((i) => comparator(curr, i))
    if (found) {
      return diffs
    } else {
      return diffs.concat([curr])
    }
  }, [] as T[])
  const inBNotA = b.reduce((diffs, curr) => {
    const found = a.find((i) => comparator(curr, i))
    if (found) {
      return diffs
    } else {
      return diffs.concat([curr])
    }
  }, [] as T[])
  return [inANotB, inBNotA]
}

export { groupBy, diff }
