import { Environment, Network, RecordSource, RequestParameters, Store, Variables } from 'relay-runtime'

import Logger from './utils/dev-logging'
import fetchGraphQL from './utils/fetchGraphQL'

// Relay passes a "params" object with the query name and text. So we define a helper function
// to call our fetchGraphQL utility with params.text.
/* eslint-disable-next-line */
async function fetchRelay(params: RequestParameters, variables: Variables) {
  Logger.info(`fetching query ${params.name} with ${JSON.stringify(variables)}`)
  return fetchGraphQL(params.text, variables)
}

// Export a singleton instance of Relay Environment configured with our network function:
export default new Environment({
  network: Network.create(fetchRelay),
  store: new Store(new RecordSource()),
})
