import React, { useLayoutEffect, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'

import SearchBar from '../../components/SearchBar'

const closePath = (
  <path
    fillRule="evenodd"
    d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
  />
)

const openPath = (
  <path
    fillRule="evenodd"
    d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
  />
)

const nav = (
  <nav className="px-2 pt-2 pb-4 sm:flex sm:p-0">
    <Link
      to="/chart-selections"
      className="mt-1 block px-2 py-1 font-normal rounded hover:text-themeblue-500 sm:mt-0 sm:ml-2">
      Chart Selections
    </Link>
  </nav>
)

export default function Navbar({ updateHeightOffset }: { updateHeightOffset: (h: string) => void }) {
  const [isOpen, setIsOpen] = useState(false)
  const match = useRouteMatch({
    path: '/',
    exact: true,
    strict: true,
  })

  useLayoutEffect(() => {
    const el = document.getElementById('navbar')
    if (el) {
      updateHeightOffset(getComputedStyle(el).height)
    }
  }, [isOpen, updateHeightOffset])

  const recalcHeight = () => {
    setIsOpen(!isOpen)
    const el = document.getElementById('navbar')
    if (el) {
      updateHeightOffset(getComputedStyle(el).height)
    }
  }

  const ishidden = match ? 'hidden' : ''

  return (
    <header id="navbar" className={`fixed z-50 w-full bg-white sm:px-4 sm:py-3 text-themeblack ${ishidden}`}>
      <div className="flex items-center justify-between md:ml-10 px-4 py-3 sm:p-0">
        <Link to="/">
          <h1 className="text-2xl text-themeblack font-semibold tracking-widest uppercase">Flyway</h1>
        </Link>
        <div className="w-3/4 md:w-1/2 m-auto flex mx-1">
          <div className="w-full m-auto">
            <SearchBar />
          </div>
        </div>
        <div className="flex justify-center">
          <div className="sm:hidden flex justify-center">
            <button onClick={() => recalcHeight()} type="button" className="block focus:outline-none rounded">
              <svg className="h-6 w-6 text-themeblack fill-current" viewBox="0 0 24 24">
                {isOpen ? closePath : openPath}
              </svg>
            </button>
          </div>
          <div className="hidden sm:flex">{nav}</div>
        </div>
      </div>
      <div className="sm:hidden">{isOpen ? nav : ''}</div>
    </header>
  )
}
