// APP
// This flag here can safely be set to true or false to switch to
// using the local server (on your machine) or production. Do not
// alter any of the other constants for environment specific reasons
const PROD_API_OVERRIDE = process.env.REACT_APP_USE_PROD_API === 'true' || false
export const IS_LOCAL = typeof window !== 'undefined' && window.document.location.href.includes('localhost')
export const API_PROTOCOL = IS_LOCAL && !PROD_API_OVERRIDE ? 'http://' : 'https://'
export const API_HOST =
  IS_LOCAL && !PROD_API_OVERRIDE ? 'localhost:4000' : `${process.env.REACT_APP_API_PREFIX || ''}api.flyway.cc`
export const API_PATH = '/api/v1'
export const API_BASE_URL = `${API_PROTOCOL}${API_HOST}${API_PATH}`
export const API_AUTH_TOKEN = process.env.REACT_APP_API_AUTH_TOKEN
// Leaflet
export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiYXJlbGVuZ2xpc2giLCJhIjoiY2ptemJjdnp0MHU5ZzNrcjJqNjdncndzMSJ9.pmanP95PfBo_4GgDmcN_9Q'
export const MAPBOX_STYLE_ID = 'cj68kq87a1gqk2srsnul2iwee'
export const MAPBOX_USERNAME = 'arelenglish'
export const MAPBOX_DEFAULT_MAP = `https://api.mapbox.com/styles/v1/${MAPBOX_USERNAME}/${MAPBOX_STYLE_ID}/tiles/256/{z}/{x}/{y}@2x?access_token=${MAPBOX_ACCESS_TOKEN}`
export const MAPBOX_SATELLITE = `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v9/tiles/256/{z}/{x}/{y}?access_token=${MAPBOX_ACCESS_TOKEN}`
export const LEAFLET_SATELLITE = `https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}`
console.log(API_HOST)
