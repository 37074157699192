export default function addSessionIdToUrl(sessionId: string | null) {
  if (sessionId && window.location.href.includes(sessionId)) return
  const base = window.location.pathname
    .split('/')
    .filter((n) => n.length > 0)
    .join('/')
    .split('/route/')[0]
  if (sessionId === null) {
    /* eslint-disable-next-line */
    history.replaceState({}, document.title, `/${base}`)
    return
  }

  const url = `/${base}/route/${sessionId}`

  /* eslint-disable-next-line */
  history.pushState({}, document.title, url)
}
